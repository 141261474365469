<template>
	<div class="card py-2">
		<div class="tabs px-2 mb-1">
			<ul class="nav nav-tabs border-bottom">
				<li class="nav-item">
					<button class="nav-link" :class="{ 'active': tab === 'details' }"
						@click="() => tab = 'details'">Details
					</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{ 'active': tab === 'portfolio' }"
						@click="() => tab = 'portfolio'">Portfolio Images
					</button>
				</li>
        <li class="nav-item">
					<button class="nav-link" :class="{ 'active': tab === 'comments' }"
						@click="fetchDesignerComments">Comments
					</button>
				</li>
			</ul>
		</div>
		<section v-if="tab === 'details'">

			<b-card-form>
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
					<p style="color: #AF7F4A;font-weight: 600;" v-html="professional.publishMessage"></p>
				</b-col>
				</b-row>

				<b-row class="align-items-center justify-content-between">
					<!-- Profile Image on the Left -->
					<div class="profile-picture">
						<img :src="professional.details.personalImageUrl "
							class="rounded-pill mb-1 ml-1" width="60" height="60" alt="Profile Image">
					</div>
				</b-row>

        <!-- "Mark as Top Designer" & "Mark as Highly Responsive" -->
        <b-row class="mb-3 mt-2">
          <b-col class="d-flex" style="gap: 50px">
            <div class="d-flex">
              <div class="mr-2 text-dark font-bold">Mark as Top Designer</div>
              <b-form-checkbox v-model="professional.details.isTopDesigner" name="check-button" switch
                               @change="handleTopDesignerSwitchChange(professional.details.isTopDesigner)" />
            </div>

            <div class="d-flex">
              <div class="mr-2 text-dark font-bold">Mark as Highly Responsive</div>
              <b-form-checkbox v-model="professional.details.isHighlyResponsive" name="check-button" switch
                               @change="handleHighlyResponsiveSwitchChange(professional.details.isHighlyResponsive)" />
            </div>
          </b-col>
        </b-row>


				<!-- First Name and Last Name -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="First Name" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.firstName || "N/A" }}</p>
						</b-form-group>
					</b-col>


					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Name" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.lastName || "N/A" }}</p>
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="First Name Arabic" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.firstNameArabic || "N/A" }}</p>
						</b-form-group>
					</b-col>


					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Name Arabic" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.lastNameArabic || "N/A" }}</p>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Email and Phone -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Email Address" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.emailAddress || "N/A" }}</p>
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Phone number" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.cellPhone || "N/A" }}</p>
						</b-form-group>

					</b-col>
				</b-row>

				<!-- Bio and Headline-->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Bio" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.bio || "N/A" }}</p>
						</b-form-group>

					</b-col>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Headline" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.headline || "N/A" }}</p>
						</b-form-group>

					</b-col>
				</b-row>
				<hr>

				<!-- Profession and Country -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Profession" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.profession.text || "N/A" }}</p>
						</b-form-group>

					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Country" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.country.name || "N/A" }}</p>
						</b-form-group>

					</b-col>
				</b-row>

				<!--	Hourly rate and Status	-->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Hourly Rate" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.hourlyRate || "N/A" }}</p>
						</b-form-group>

					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Status" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.status || "N/A" }}</p>
						</b-form-group>

					</b-col>

				</b-row>

				<!-- Last Logged In -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Logged In" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ getFormattedDate(professional.details.lastLoggedInUTC)
								||"N/A"}}</p>
						</b-form-group>

					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Modified By" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.modifiedBy || "N/A" }}</p>
						</b-form-group>


					</b-col>
				</b-row>

				<!-- Bainkom Percentage and ModifiedBy -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Bainkom Percentage" label-class="font-weight-bold">
							<p class="form-control-plaintext">% {{ professional.details.bainkomPercentage  ||"N/A"}}</p>
						</b-form-group>

					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Number Of Views" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.views || "N/A" }}</p>
						</b-form-group>

					</b-col>
				</b-row>

				<!-- AccountType And Gender -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Account Type" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.accountType
								||"N/A"}}</p>
						</b-form-group>

					</b-col>

					<!-- <b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Gender" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professional.details.modifiedBy || "N/A" }}</p>
						</b-form-group>

					</b-col> -->
				</b-row>

			</b-card-form>
			<hr>

			<!--	Degrees, Certificates, Linkedin and Website	-->
			<b-card-form
				v-if="professional.degrees || professional.certificates || professional.details.linkedInUrl || professional.details.websiteUrl || professional.details.styles || professional.details.skills || professional.languages">

				<!-- Styles -->
				<div v-if="professional.details.styles">
					<h3 class="mb-2">Styles</h3>
					<b-row>
						<b-col md="3" xl="3" class="mb-1" v-for="(style, index) of professional.details.styles"
							:key="index">
							<b-form-group :label="'Style ' + (index + 1)" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ style.text || "N/A" }}</p>
						</b-form-group>

						</b-col>
					</b-row>
				</div>

				<!-- Skilld -->
				<div v-if="professional.details.skills">
					<h3 class="mb-2">Skills</h3>
					<b-row>
						<b-col md="3" xl="3" class="mb-1" v-for="(skill, index) of professional.details.skills"
							:key="index">
							<b-form-group :label="'skill ' + (index + 1)" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ skill.text || "N/A" }}</p>
						</b-form-group>

						</b-col>
					</b-row>
				</div>

				<!-- Languages -->
				<div v-if="professional.languages">
					<h3 class="mb-2">Languages</h3>
					<b-row>
						<b-col md="3" xl="3" class="mb-1" v-for="(language, index) of professional.languages"
							:key="index">
							<b-form-group :label="'language ' + (index + 1)" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ language.text || "N/A" }}</p>
						</b-form-group>

						</b-col>
					</b-row>
				</div>

				<!-- Degrees -->
				<div v-if="professional.degrees">
					<h3 class="mb-2">Degrees</h3>
					<b-row>
						<b-col md="6" xl="6" class="mb-1" v-for="(professionalDegree, index) of professional.degrees"
							:key="index">
							<b-form-group :label="'Degree ' + (index + 1)" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ professionalDegree.educationLevel.text + ' in ' + professionalDegree.degree }}</p>
						</b-form-group>

						</b-col>
					</b-row>
				</div>

				<!-- Certificates -->
				<div v-if="professional.certificates">
					<h3 class="mb-2">Certificates</h3>
					<b-row>
						<b-col md="6" xl="6" class="mb-1" v-for="(certificate, index) of professional.certificates"
							:key="index">
							<b-form-group :label="'Certificate ' + (index + 1)" label-class="font-weight-bold">
							<p class="form-control-plaintext">{{ certificate || "N/A" }}</p>
						</b-form-group>

						</b-col>
					</b-row>
				</div>

				<!-- Urls -->
				<div v-if="professional.details.linkedInUrl || professional.details.websiteUrl">
					<h3 class="mb-2">Urls</h3>
					<b-row>
						<!-- Linkedin Url	-->
						<b-col md="6" xl="6" class="mb-1" v-if="professional.details.linkedInUrl">
							<b-form-group label="Linkedin Url">
								<a :href="professional.details.linkedInUrl" target="_blank" class="form-control"
									style="background-color:#efefef;">
									{{ professional.details.linkedInUrl }}
								</a>
							</b-form-group>
						</b-col>

						<!-- Website Url	-->
						<b-col md="6" xl="6" class="mb-1" v-if="professional.details.websiteUrl">
							<b-form-group label="Website Url">
								<a :href="professional.details.websiteUrl" target="_blank" class="form-control"
									style="background-color:#efefef;">
									{{ professional.details.websiteUrl }}
								</a>
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-card-form>

			<!-- Files -->
			<b-card-form>
				<h3>Files</h3>
				<b-row>
					<!-- resume -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.resumeUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Resume</h6>
								</div>
							</div>
							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon" :href="professional.details.resumeUrl"
									target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>

					<!-- cover letter -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.coverLetterUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Cover Letter</h6>
								</div>
							</div>

							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon"
									:href="professional.details.coverLetterUrl" target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>

					<!-- portfolio -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.portfolioUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Portfolio</h6>
								</div>
							</div>

							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon" :href="professional.details.portfolioUrl"
									target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-card-form>
		</section>

		<section v-if="tab === 'portfolio'">
			<div class="d-flex flex-row w-100 justify-content-end p-1">
				<b-button size="md" variant="primary" @click="() => saveAll()">
					<span class="align-middle">Save All</span>
				</b-button>
			</div>
			<div v-for="item in portfolioItems"
				class="portfolio-item-container d-flex flex-row justify-content-between align-items-center">
				<div class=" d-flex flex-row justify-content-start align-items-center w-75">
					<div class="p-3">
						<b-img :src="item.url" width="300px" />
					</div>
					<div class="d-flex flex-column w-100 p-3">
						<div class="d-flex flex-column w-100 mb-3">
							<div class="mb-1 font-weight-bold"> Add/Edit Tags </div>
							<div class="select-cotainer">
								<div class="d-flex flex-column ">
									<v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" taggable multiple
										:reduce="reduceTag" :options="tags" label="name" v-model="item.tags"
										:create-option="createTag" @option:selected="(tag) => optionSelected(tag, item)"
										:closeOnSelect="false" class="select" />

									<div class="validation-text d-flex flex-row justify-content-end mt-1">
										Max 10
									</div>
								</div>

								<div class="select-actions-container">
									<b-button class="copy-paste-btn" variant="primary" @click="() => copyTags(item)">
										<feather-icon icon="CopyIcon" width="15" height="15" />
									</b-button>
									<b-button v-if="isCopying && itemCopied.id !== item.id" class="copy-paste-btn"
										variant="primary" @click="() => pasteTags(item)">
										<feather-icon icon="FilePlusIcon" width="15" height="15" />
									</b-button>
								</div>
							</div>

						</div>
						<div class="d-flex flex-column w-100 mb-3" style="max-width: 400px;">
							<div class="font-weight-bold">
								<b-form-group label="Add Alt Text (optional)">
									<b-form-input v-model="item.altText" placeholder="Alt Text" />
								</b-form-group>
							</div>
							<div v-if="item.altTextError"
								class="validation-text-error d-flex flex-row justify-content-end w-100">
								{{ item.altTextError }}
							</div>
							<div v-else class="validation-text d-flex flex-row justify-content-end w-100">
								At least 12 characters
							</div>
						</div>
					</div>
				</div>
				<div class="action-button-container d-flex flex-column p-3">
					<b-button size="md" variant="primary" @click="() => saveTags(item.id)">
						<span class="align-middle">Save Changes</span>
					</b-button>
				</div>
			</div>
		</section>

    <section v-if="tab === 'comments'">
      <div>
        <b-card no-body class="mb-0 p-1 align-item-center">

          <div class="d-flex flex-row w-100 h-100 justify-content-around mb-1">
            <b-form-textarea v-model="newComment" placeholder="Write a comment..." rows="3" />
          </div>
          <div class="d-flex flex-row w-100 h-100 justify-content-start mb-3">
            <b-button variant="primary" @click="addComment">
              <span class="text-nowrap">Add Comment</span>
            </b-button>
          </div>

          <div v-for="(comment, index) in comments" :key="index">
            <div class="d-flex flex-column w-100 border-bottom mb-2 p-1">
              <div class="d-flex flex-row justify-content-start align-items-center mb-1">
                <div class="profile-pic">

                </div>
                <div class="align-items-center d-flex justify-content-between w-100">
                  <span>{{ comment.ownerName }}</span>
                  <span>{{ formatTime(comment.createdOn) }}</span>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-start align-items-center p-1">
                {{ comment.comment }}
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </section>
	</div>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BFormCheckbox, BImg, BCard} from 'bootstrap-vue';
import moment from 'moment';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


export default {
	props: ['professionalId'],

	components: {
    BCard,
		BCardForm,
		BFormInput,
		BFormGroup,
		BFormTextarea,
		BRow,
		BCol,
		BButton,
		BFormCheckbox,
		BImg,
		vSelect
	},

	data() {
		return {
			isLoading: false,
			professional: {
				details: {
					firstName: null,
					lastName: null,
					emailAddress: null,
					cellPhone: null,
					personalImageUrl: null,
					profession: {
						value: null,
						text: null,
					},
					country: {
						code: null,
						name: null,
						numericCode: null,
						dialCode: null,
					},
					status: null,

					bio: null,
					lastLoggedInUTC: null,
					resumeUrl: null,
					coverLetterUrl: null,
					portfolioUrl: null,
					websiteUrl: null,
					linkedInUrl: null,
					isTopDesigner: null,
          isHighlyResponsive: null
				},
				hourlyRate: null,

				certificates: null,
			},
			tab: 'details',
			tags: [],
			portfolioItems: [],
			inputTimer: null,
			isCopying: false,
			itemCopied: {},
			copiedTags: [],
      newComment: null,
      comments: null
		}
	},

	methods: {
		copyTags(item) {
			const tags = item.tags;
			this.isCopying = true;
			this.copiedTags = tags;
			this.itemCopied = item;
		},
		async pasteTags(item) {
			item.tags = this.copiedTags
		},
		getFormattedDate(dateTime) {
			const localDate = moment(dateTime).local();
			const formattedDate = localDate.format("YYYY-MM-DD, h:mm:ss A");

			return formattedDate || null;
		},

		async handleTopDesignerSwitchChange(checked) {
			await this.$http.put(`professionals/${this.professionalId}/TopDesigner`,
				{ IsTopDesigner: checked });

			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Updated Successfully',
					icon: 'CheckCircleIcon',
					variant: 'success',
				},
			});
		},

    async handleHighlyResponsiveSwitchChange(checked) {
      await this.$http.put(`professionals/${this.professionalId}/HighlyResponsive`,
          { isHighlyResponsive: checked });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Updated Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },

		createTag(tag) {
			const tagName = tag.trim().toLowerCase()

			const found = this.tags.find(x => x.name === tagName)

			console.log('FOUND: ', found);
			if (!found) {
				console.log('NEW: ');
				return {
					name: tagName
				}
			} else {
				console.log('EXISTING: ');
				return found
			}
		},
		reduceTag(option) {
			return { ...option, name: option.name.trim().toLowerCase() }
		},
		handleOptionCreated(option) {
			const newTag = {
				id: null,
				...option
			}
			this.tags.push(newTag)
			return option
		},
		optionSelected(tag, item) {
			if (item.tags.length > 10) {
				item.tags.pop();
			}
		},
		async saveTags(itemId) {
			const item = this.portfolioItems ? this.portfolioItems.find(x => x.id === itemId) : []
			if (item.altText && item.altText.length < 12) {
				item.altTextError = "Must be at least 12 characters"
				return
			} else {
				item.altTextError = ''
			}
			const tags = item.tags ?? []
			await this.$http.post(`professionals/${this.professionalId}/PortfioloItems/${itemId}/tags`,
				{ tags: tags, altText: item.altText });
			await this.onCreated();
		},

		async saveAll() {

			let requestBody = []

			this.portfolioItems.forEach(item => {
				const tags = item.tags ?? []

				requestBody.push(
					{
						PortfolioItemId: item.id,
						body: { tags, altText: item.altText }
					}
				)
			});

			await this.$http.post(
				`professionals/${this.professionalId}/PortfioloItems/tags`,
				requestBody
			);
			await this.onCreated();
		},

		async onCreated() {
			try {
				this.isLoading = true;
				const professionalPromise = this.$http.get(`professionals/${this.professionalId}`);
				const tagsPromise = this.$http.get('/Lookups/PortfolioItemTagList');

				const [professional, tags] = await Promise.all([professionalPromise, tagsPromise]);

				this.professional = professional.data;
				this.tags = tags && tags.data?.length ? tags.data : [];

				this.portfolioItems = professional.data.designProfolioItems.map(x => ({
					...x,
					...this.portfolioItems.find(i => i.id === x.id),
					altTextError: ''
				}));
			} catch (error) {
			}
			this.isLoading = false;
		},
    async fetchDesignerComments() {
      const response = await this.$http.get(`Professionals/${this.professionalId}/comments`);

      this.comments = response.data;

      this.tab = 'comments';
    },
    async addComment() {
      await this.$http.post(`Professionals/${this.professionalId}/Comment`, {
        comment: this.newComment
      });

      await this.fetchDesignerComments();

      this.newComment = null;
    },

    formatTime(date) {
      return moment(date).format('YYYY-MM-DD, h:mm a');
    }
	},

	async created() {
		await this.onCreated()
	}
}



</script>


<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes
@import '~@core/scss/base/core/colors/palette-variables.scss';

.tabs {
	.nav-link {
		background-color: white;
	}
}

.portfolio-item-container {
	min-height: 300px;
	border: 1px solid $border-color;
	margin: 0 10px;

	.action-button-container {
		min-height: 300px;
	}
}

.validation-text {
	font-size: 12px;
}

.validation-text-error {
	font-size: 12px;
	color: red;
}

.copy-paste-btn {
	padding: 7px;
}

.select-cotainer {
	display: flex;
	flex-direction: row;
	gap: 10px;
	// align-items: center;
}

.select {
	min-width: 400px;
}

.select-actions-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 3px;
	height: 100%;
	align-items: center;

}
</style>
